import React from 'react'
import PropTypes from 'prop-types'
import { DEFAULT, GREEN, LIGHT_BLUE, LIGHT_GREEN, RED, YELLOW } from '../../../constants/buttons'
import './index.css'

const Button = React.forwardRef(({ children, type, className, onClick, isDisabled }, ref) => {
  const classNames = [
    'common-button',
    [...isDisabled ? ['common-button_disabled'] : []],
    [...type === DEFAULT ? ['common-button_default'] : []],
    [...type === GREEN ? ['common-button_green'] : []],
    [...type === LIGHT_GREEN ? ['common-button_light-green'] : []],
    [...type === RED ? ['common-button_red'] : []],
    [...type === YELLOW ? ['common-button_yellow'] : []],
    [...type === LIGHT_BLUE ? ['common-button_light-blue'] : []],
    [...className ? [className] : []],
  ]
  return (
    <div ref={ref} className={classNames.join(' ')} onClick={isDisabled ? undefined : onClick}>
      {children}
    </div>
  )
})

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.oneOf([DEFAULT, GREEN, LIGHT_GREEN, RED, LIGHT_BLUE]),
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
}

Button.defaultProps = {
  className: undefined,
  type: DEFAULT,
  onClick: undefined,
  isDisabled: false,
}

export default Button
