function ConvertDMSToDD(degrees, minutes, seconds, direction) {
  let dd = parseFloat(degrees) + parseFloat(minutes) / 60 + parseFloat(seconds) / (60 * 60)

  if (direction === 'S' || direction === 'W') {
    dd *= -1
  }

  return dd.toString().replace(',', '.')
}

const DmsToDd = (input) => {
  let lat
  let lng
  if (/[°˚]/.test(input)) {
    const parts = input.trim().split(/[^\w.]+/)
    lat = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3])
    lng = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7])
  } else {
    const directionLat = input.includes('N') ? 'N' : 'S'
    const directionLng = input.includes('E') ? 'E' : 'W'
    const [latStr, lngStr] = input.trim().split(/\s+/)
    lat = ConvertDMSToDD(latStr.substr(0, 2), latStr.substr(2, 2), latStr.substr(4, 2), directionLat)
    lng = ConvertDMSToDD(lngStr.substr(0, 3), lngStr.substr(3, 2), lngStr.substr(5, 2), directionLng)
  }
  return `${lat}, ${lng}`
}

export default DmsToDd
