import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import 'moment/locale/uk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faSave } from '@fortawesome/free-regular-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import fetch from '../../../utils/fetch'
import { UserContext } from '../../../contexts/user'
import { GREEN, RED, YELLOW } from '../../../constants/buttons'
import Input from '../../Common/Input'
import Button from '../../Common/Button'
import './index.css'

const ListItem = (
  { id, callsign, callsign_tlg, correspondent, comment, unsure, onDelete, onUpdate },
) => {
  const [isEditable, setIsEditable] = useState(false)
  const [currCallsign, setCurrCallsign] = useState(callsign)
  const [currCallsignTlg, setCurrCallsignTlg] = useState(callsign_tlg)
  const [currCorrespondent, setCurrCorrespondent] = useState(correspondent)
  const [currComment, setCurrComment] = useState(comment)
  const [currUnsure, setCurrUnsure] = useState(!!unsure)
  const user = useContext(UserContext)
  const isAdmin = user.permissions.includes('write')
  const classNames = [
    'info-list-item',
    ...[isEditable ? ['info-list-item_editable'] : []],
    ...[currUnsure ? ['info-list-item_unsure'] : []],
  ]

  const onCancel = () => {
    setCurrCallsign(callsign)
    setCurrCallsignTlg(callsign_tlg)
    setCurrCorrespondent(correspondent)
    setCurrComment(comment)
    setCurrUnsure(unsure)
    setIsEditable(false)
  }

  const onSetEditable = () => (
    isAdmin && !isEditable ? setIsEditable(true) : undefined
  )

  const onSave = () => {
    fetch(
      `${process.env.REACT_APP_API_HOST}/info`, {
        method: 'PUT',
        body: JSON.stringify({
          id,
          callsign: currCallsign,
          callsign_tlg: currCallsignTlg,
          correspondent: currCorrespondent,
          comment: currComment,
          unsure: currUnsure,
        }),
      }).then((response) => {
      if (response) {
        onUpdate(response)
        setIsEditable(false)
      } else {
        toast.error('Під час збереження сталася помилка')
      }
    }).catch(() => {})
  }

  return (
    <div className={classNames.join(' ')} onClick={onSetEditable}>
      <div className={`info-list-item__item ${!currCorrespondent && !isEditable ? 'info-list-item__item_empty' : ''}`}>
        {isEditable && (
          <Input
            onChange={(e) => setCurrCorrespondent(e.currentTarget.value)}
            value={currCorrespondent}
            placeholder="Кореспондент"
          />
        )}
        {!isEditable && (
          <div className="info-list-item__item-text info-list-item__item-text_correspondent">{currCorrespondent}</div>
        )}
      </div>
      <div className={`info-list-item__item ${!currCallsign && !isEditable ? 'info-list-item__item_empty' : ''}`}>
        {isEditable && (
          <Input
            onChange={(e) => setCurrCallsign(e.currentTarget.value)}
            value={currCallsign}
            placeholder="Позивний ТЛФ"
          />
        )}
        {!isEditable && (
          <div className="info-list-item__item-text info-list-item__item-text_callsign">{currCallsign}</div>
        )}
      </div>
      <div className={`info-list-item__item ${!currCallsignTlg && !isEditable ? 'info-list-item__item_empty' : ''}`}>
        {isEditable && (
          <Input
            onChange={(e) => setCurrCallsignTlg(e.currentTarget.value)}
            value={currCallsignTlg}
            placeholder="Позивний ТЛГ"
          />
        )}
        {!isEditable && (
          <div className="info-list-item__item-text info-list-item__item-text_callsign-tlg">{currCallsignTlg}</div>
        )}
      </div>
      <div className={`info-list-item__item ${!currComment && !isEditable ? 'info-list-item__item_empty' : ''}`}>
        {isEditable && (
          <Input
            onChange={(e) => setCurrComment(e.currentTarget.value)}
            value={currComment}
            placeholder="Коментар"
          />
        )}
        {!isEditable && (
          <div className="info-list-item__item-text info-list-item__item-text_comment">{currComment}</div>
        )}
      </div>
      {isEditable && (
        <div className="info-list-item__item">
          <input
            type="checkbox"
            className="info-list-item__item-checkbox"
            id={`unsure-${id}`}
            name="unsure"
            onChange={(e) => setCurrUnsure(e.target.checked)}
            checked={currUnsure}
          />
          <label htmlFor={`unsure-${id}`}>
            Потребує уточнення
          </label>
        </div>
      )}
      {isAdmin && isEditable && (
        <div className="info-list-item__item">
          <div className="info-list-item__item-buttons">
            <div className="info-list-item__item-button">
              <Button type={GREEN} onClick={() => onSave(id)}>
                <FontAwesomeIcon icon={faSave} color="#ffffff" />
              </Button>
            </div>
            <div className="info-list-item__item-button">
              <Button type={YELLOW} onClick={() => onCancel()}>
                <FontAwesomeIcon icon={faTimes} color="#ffffff" />
              </Button>
            </div>
            <Button type={RED} onClick={() => onDelete(id)}>
              <FontAwesomeIcon icon={faTrashCan} color="#ffffff" />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

ListItem.propTypes = {
  id: PropTypes.number.isRequired,
  callsign: PropTypes.string.isRequired,
  callsign_tlg: PropTypes.string,
  correspondent: PropTypes.string,
  comment: PropTypes.string,
  unsure: PropTypes.number,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
}

ListItem.defaultProps = {
  callsign_tlg: '',
  correspondent: '',
  comment: '',
  unsure: 0,
  onDelete: () => {},
  onUpdate: () => {},
}

export default ListItem
