import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import DmsToDd from '../../utils/dmsToDd'
import getXYCoords from '../../utils/getXYCoords'
import Button from '../Common/Button'
import { LIGHT_BLUE } from '../../constants/buttons'
import './index.css'
import MapForm from '../Common/MapForm'

const CoordsConverter = () => {
  const [inputValue, setInputValue] = useState('')
  const [coordsConvertResult, setCoordsConvertResult] = useState('')
  const [error, setError] = useState('')

  const onFormClear = () => {
    setInputValue('')
    setError('')
    setCoordsConvertResult('')
  }

  const onChangeCoordinates = (event) => {
    setInputValue(event.currentTarget.value)
  }

  const onFormSubmit = (event) => {
    event.preventDefault()
    const coordinates = /[°˚NSEW]/.test(inputValue) ? DmsToDd(inputValue) : inputValue
    const [lat, lng] = coordinates.split(/[^-\d.]+/)
    const latFloat = parseFloat(lat)
    const lngFloat = parseFloat(lng)
    if (Math.abs(latFloat) <= 90 && Math.abs(lngFloat) <= 180) {
      try {
        const convertResult = getXYCoords(latFloat, lngFloat)
        setCoordsConvertResult(convertResult)
        setError('')
      } catch (e) {
        // console.log(e)
        setError('Помилка конвертування')
        setCoordsConvertResult('')
      }
    } else {
      setError('Невірний формат координат')
      setCoordsConvertResult('')
    }
  }

  return (
    <div className="coords-converter">
      <div className="coords-converter__form">
        <MapForm
          title="Конвертувати в прямокутні координати"
          buttonSubmitTitle="Конвертувати"
          onChange={onChangeCoordinates}
          onSubmit={onFormSubmit}
          onClear={onFormClear}
          data={inputValue}
          isDisabled={inputValue.length === 0}
        />
      </div>
      {error && (
        <div className="coords-converter__error">{error}</div>
      )}
      {coordsConvertResult && (
        <div className="coords-converter__result">
          <div className="coords-converter__result-text">{coordsConvertResult}</div>
          <div className="coords-converter__result-button">
            <CopyToClipboard
              text={coordsConvertResult}
              onCopy={() => toast.info('Скопійовано!')}
            >
              <Button type={LIGHT_BLUE}>
                Копіювати
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </div>
  )
}

export default CoordsConverter
