/* eslint-disable no-mixed-operators */

const toRadians = (degrees) => degrees * Math.PI / 180

const toDegrees = (radians) => radians * 180 / Math.PI

const getHumanReadableDirection = (bearing) => {
  switch (true) {
    case bearing >= 22.5 && bearing < 67.5: return 'північний схід'
    case bearing >= 67.5 && bearing < 112.5: return 'схід'
    case bearing >= 112.5 && bearing < 157.5: return 'південний схід'
    case bearing >= 157.5 && bearing < 202.5: return 'південь'
    case bearing >= 202.5 && bearing < 247.5: return 'південний захід'
    case bearing >= 247.5 && bearing < 292.5: return 'захід'
    case bearing >= 292.5 && bearing < 337.5: return 'північний захід'
    default: return 'північ'
  }
}

const getBearing = (point1, point2, humanReadable = false) => {
  const { lat: lat1, lng: lon1 } = point1
  const { lat: lat2, lng: lon2 } = point2
  const startLat = toRadians(lat1)
  const startLng = toRadians(lon1)
  const destLat = toRadians(lat2)
  const destLng = toRadians(lon2)
  const y = Math.sin(destLng - startLng) * Math.cos(destLat)
  const x = Math.cos(startLat) * Math.sin(destLat)
    - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng)
  const degrees = (toDegrees(Math.atan2(y, x)) + 360) % 360
  return humanReadable ? getHumanReadableDirection(degrees) : degrees
}

export default getBearing
