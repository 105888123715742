import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Navigate } from 'react-router'
import { ToastContainer, Slide } from 'react-toastify'
import { UserContext } from '../../contexts/user'
import Menu from '../Menu'
import Info from '../Info'
import Auth from '../Auth'
import Home from '../Home'
import Users from '../Users'
import MapZone from '../MapZone'
import CoordsConverter from '../CoordsConverter'
import BeaconsEditor from '../BeaconsEditor'
import DistanceFinder from '../DistanceFinder'
import { ADMINISTER, READ, WRITE } from '../../constants/permissions'
import './index.css'
import Points from '../Points'

const App = () => {
  const user = useContext(UserContext)
  const canRead = user && user.permissions.includes(READ)
  const canWrite = user && user.permissions.includes(WRITE)
  const isAdmin = user && user.permissions.includes(ADMINISTER)

  return (
    <>
      <Menu />
      {user && (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/zone" element={<MapZone />} />
          <Route path="/points" element={<Points />} />
          <Route path="/distance" element={<DistanceFinder />} />
          <Route path="/converter" element={<CoordsConverter />} />
          {canRead && <Route path="/info" element={<Info />} />}
          {canWrite && <Route path="/beacons" element={<BeaconsEditor />} />}
          {isAdmin && <Route path="/admin/users" element={<Users />} />}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      {!user && <Auth />}
      <ToastContainer position="top-center" hideProgressBar autoClose={2000} transition={Slide} />
    </>
  )
}

export default App
