import getCurrentUser from './user/get'
import eventBus from './eventBus'
import saveCurrentUser from './user/save'

export const customFetchOrThrow = async (url, options) => {
  const { headers = {}, ...restOptions } = options || {}
  const user = getCurrentUser()
  const { accessToken } = user || {}
  const fetchOptions = {
    ...restOptions,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(accessToken ? { 'x-access-token': accessToken } : {}),
      ...headers,
    },
  }

  const response = await fetch(url, fetchOptions)

  if (response.ok === false) {
    const error = await response.json()
    error.status = response.status
    if (response.status === 401 && error.name !== 'TokenExpiredError') {
      eventBus.dispatch('logout')
    }
    throw error
  }

  if (response.status === 204) {
    return {}
  }

  try {
    return await response.json()
  } catch (e) {
    return null
  }
}

const refresh = async () => {
  const user = getCurrentUser()
  const { refreshToken } = user || {}
  await customFetchOrThrow(
    `${process.env.REACT_APP_API_HOST}/user/refresh`,
    {
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
    },
  ).then((newUser) => {
    saveCurrentUser(newUser)
    eventBus.dispatch('refresh', newUser)
  })
}

const customFetch = async (url, options) => customFetchOrThrow(url, options).catch(async (e) => {
  if (e.name === 'TokenExpiredError') {
    await refresh()
    return customFetchOrThrow(url, options)
  }
  return Promise.reject(e)
})

export default customFetch
