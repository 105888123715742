import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import saveCurrentUser from '../utils/user/save'
import removeCurrentUser from '../utils/user/remove'
import getCurrentUser from '../utils/user/get'
import fetch from '../utils/fetch'
import eventBus from '../utils/eventBus'

export const UserContext = React.createContext(undefined)
export const UserLoginContext = React.createContext(() => {})
export const UserLogoutContext = React.createContext(() => {})

export const UserProvider = ({ children }) => {
  const [currUser, setCurrUser] = useState(getCurrentUser())

  const onLogIn = useCallback((user) => {
    saveCurrentUser(user)
    setCurrUser(user)
  }, [])

  const onLogOut = useCallback(() => {
    removeCurrentUser()
    setCurrUser(undefined)
  }, [])

  const onRefresh = useCallback((user) => {
    setCurrUser(user)
  }, [])

  useEffect(() => {
    // to check if access token is valid
    fetch(`${process.env.REACT_APP_API_HOST}/user`).catch(() => {})
  }, [])

  useEffect(() => {
    eventBus.on('logout', onLogOut)
    return () => eventBus.remove('logout')
  }, [onLogOut])

  useEffect(() => {
    eventBus.on('refresh', onRefresh)
    return () => eventBus.remove('refresh')
  }, [onRefresh])

  return (
    <UserContext.Provider value={currUser}>
      <UserLoginContext.Provider value={onLogIn}>
        <UserLogoutContext.Provider value={onLogOut}>
          {children}
        </UserLogoutContext.Provider>
      </UserLoginContext.Provider>
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
