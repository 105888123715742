import React from 'react'
import PropTypes from 'prop-types'
import Textarea from '../Textarea'
import { GREEN, YELLOW } from '../../../constants/buttons'
import Button from '../Button'
import Input from '../Input'
import './index.css'

const MapForm = ({
  onSubmit,
  onChange,
  onClear,
  data,
  isDisabled,
  title,
  buttonSubmitTitle,
  textRows,
  showChangeShowPoints,
  onChangeShowPoints,
  showPoints,
}) => {
  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      e.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  return (
    <form onSubmit={onSubmit} className="map-form">
      <div className="map-form__title">{title}</div>
      {textRows > 1 && (
        <Textarea
          className="map-form__input"
          placeholder="Координати"
          onChange={onChange}
          rows={textRows}
          onKeyDown={onEnterPress}
        >
          {data}
        </Textarea>
      )}
      {textRows === 1 && (
        <Input
          className="map-form__input"
          placeholder="Координати"
          onChange={onChange}
          value={data}
        />
      )}
      <div className="map-form__button">
        <Button
          type={GREEN}
          onClick={onSubmit}
          isDisabled={isDisabled}
        >
          {buttonSubmitTitle}
        </Button>
      </div>
      <div className="map-form__button">
        <Button
          type={YELLOW}
          onClick={onClear}
        >
          Очистити
        </Button>
      </div>
      {showChangeShowPoints && (
        <label className="map-form__button" htmlFor="show-points">
          <input
            className="map-form__checkbox"
            type="checkbox"
            id="show-points"
            name="show-points"
            onChange={onChangeShowPoints}
            checked={showPoints}
          />
          Відображати точки прив&apos;язки
        </label>
      )}
    </form>
  )
}

MapForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonSubmitTitle: PropTypes.string.isRequired,
  data: PropTypes.string,
  isDisabled: PropTypes.bool,
  textRows: PropTypes.number,
  onChangeShowPoints: PropTypes.func,
  showChangeShowPoints: PropTypes.bool,
  showPoints: PropTypes.bool,
}

MapForm.defaultProps = {
  data: '',
  isDisabled: false,
  textRows: 1,
  showChangeShowPoints: false,
  onChangeShowPoints: () => {},
  showPoints: false,
}

export default MapForm
