import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import fetch from '../../../utils/fetch'
import { UserContext } from '../../../contexts/user'
import { ADMINISTER, PERMISSIONS } from '../../../constants/permissions'
import Button from '../../Common/Button'
import { DEFAULT, LIGHT_GREEN, RED } from '../../../constants/buttons'
import './index.css'

const Item = ({ id, name, permissions: defaultPermissions, onDelete }) => {
  const [permissions, setPermissions] = useState(defaultPermissions)
  const user = useContext(UserContext)

  const onTogglePermission = async (value) => {
    if (user.id === id && value === ADMINISTER) {
      toast.error('Не можна видалити роль адміністратора у самого себе')
      return
    }
    const newPermissions = permissions.includes(value)
      ? permissions.filter((val) => val !== value)
      : [...permissions, value]
    fetch(
      `${process.env.REACT_APP_API_HOST}/admin/user/permissions`, {
        method: 'PUT',
        body: JSON.stringify({ id, permissions: newPermissions }),
      }).then(() => {
      setPermissions(newPermissions)
    }).catch(({ error }) => {
      toast.error(error)
    })
  }

  return (
    <div className="users-item">
      <div className="users-item__name">{name}</div>
      <ul className="users-item__permissions">
        {PERMISSIONS.map((val) => {
          const type = permissions.includes(val) ? LIGHT_GREEN : DEFAULT
          return (
            <li className="users-item__permissions-item" key={val}>
              <Button type={type} onClick={() => onTogglePermission(val)}>{val}</Button>
            </li>
          )
        })}
      </ul>
      <div className="users-item__delete">
        <Button type={RED} onClick={() => onDelete(id)} isDisabled={user.id === id}>
          <FontAwesomeIcon icon={faTrashCan} color="#ffffff" />
        </Button>
      </div>
    </div>
  )
}

Item.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Item
