import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import fetch from '../../utils/fetch'
import Item from './Item'
import './index.css'

const Users = () => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/admin/users`).then((response) => {
      setUsers(response)
    }).catch(() => {})
  }, [])

  const onDeleteUser = (userId) => {
    const isSure = window.confirm('Ви дійсно бажаєте видалити цього користувача?')
    if (!isSure) {
      return
    }
    fetch(
      `${process.env.REACT_APP_API_HOST}/admin/user/${userId}`, {
        method: 'DELETE',
      }).then(() => {
      setUsers(users.filter(({ id }) => id !== userId))
    }).catch(({ error }) => {
      toast.error(error)
    })
  }

  return (
    <ul className="users">
      {users.map(({ id, name, permissions }) => (
        <li className="users__item" key={id}>
          <Item id={id} name={name} permissions={permissions} onDelete={onDeleteUser} />
        </li>
      ))}
    </ul>
  )
}

export default Users
