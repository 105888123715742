import React from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import Button from '../Button'
import { YELLOW } from '../../../constants/buttons'
import './index.css'

const CopyToClipboardPopup = ({ text, onClose }) => (
  <Popup
    defaultOpen
    closeOnDocumentClick={false}
    modal
    onClose={onClose}
    overlayStyle={{ background: 'rgba(0, 0, 0, 0.5)' }}
  >
    {(close) => (
      <div className="copy-to-clipboard-popup">
        <div className="copy-to-clipboard-popup__close" onClick={close}>
          <FontAwesomeIcon icon={faTimes} color="#555555" />
        </div>
        <div className="copy-to-clipboard-popup__text">{text}</div>
        <CopyToClipboard
          text={text}
          onCopy={() => toast.info('Скопійовано!')}
        >
          <Button type={YELLOW}>
            Копіювати
          </Button>
        </CopyToClipboard>
      </div>
    )}
  </Popup>
)

CopyToClipboardPopup.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

CopyToClipboardPopup.defaultProps = {
  onClose: () => {},
}

export default CopyToClipboardPopup
