const sk42LatToX = (B, L) => {
  const No = Math.floor((6 + L) / 6)
  const Lo = (L - (3 + 6 * (No - 1))) / 57.29577951
  const Bo = (B * Math.PI) / 180
  const sinBo = Math.sin(Bo)
  const Xa = Lo ** 2 * (109500 - 574700 * sinBo ** 2 + 863700 * sinBo ** 4 - 398600 * sinBo ** 6)
  const Xb = Lo ** 2 * (278194 - 830174 * sinBo ** 2 + 572434 * sinBo ** 4 - 16010 * sinBo ** 6 + Xa)
  const Xc = Lo ** 2 * (672483.4 - 811219.9 * sinBo ** 2 + 5420 * sinBo ** 4 - 10.6 * sinBo ** 6 + Xb)
  const Xd = Lo ** 2 * (1594561.25 + 5336.535 * sinBo ** 2 + 26.79 * sinBo ** 4 + 0.149 * sinBo ** 6 + Xc)
  return 6367558.4968 * Bo - Math.sin(Bo * 2) * (16002.89 + 66.9607 * sinBo ** 2 + 0.3515 * sinBo ** 4 - Xd)
}

const sk42LngToY = (B, L) => {
  const No = Math.floor((6 + L) / 6)
  const Lo = (L - (3 + 6 * (No - 1))) / 57.29577951
  const Bo = (B * Math.PI) / 180
  const sinBo = Math.sin(Bo)
  const Ya = Lo ** 2 * (79690 - 866190 * sinBo ** 2 + 1730360 * sinBo ** 4 - 945460 * sinBo ** 6)
  const Yb = Lo ** 2 * (270806 - 1523417 * sinBo ** 2 + 1327645 * sinBo ** 4 - 21701 * sinBo ** 6 + Ya)
  const Yc = Lo ** 2 * (1070204.16 - 2136826.66 * sinBo ** 2 + 17.98 * sinBo ** 4 - 11.99 * sinBo ** 6 + Yb)
  return (5 + 10 * No) * 10 ** 5 + Lo * Math.cos(Bo)
    * (6378245 + 21346.1415 * sinBo ** 2 + 107.159 * sinBo ** 4 + 0.5977 * sinBo ** 6 + Yc)
}

const getXYCoords = (lat, lng) => `X=${Math.round(sk42LatToX(lat, lng))} Y=${Math.round(sk42LngToY(lat, lng))}`

export default getXYCoords
