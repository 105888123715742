import getDistance from './getDistance'

const getNearestBeacon = ({ lat, lng }, beacons = []) => {
  let distance
  let nearestBeacon
  (beacons || []).forEach((beacon) => {
    const currentDistance = getDistance(beacon, { lat, lng })
    if (distance === undefined || currentDistance <= distance) {
      distance = currentDistance
      nearestBeacon = beacon
    }
  })
  return nearestBeacon
}

export default getNearestBeacon
