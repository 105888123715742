import React, { Link, NavLink } from 'react-router-dom'
import { useContext } from 'react'
import { faArrowRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserContext, UserLogoutContext } from '../../contexts/user'
import { ADMINISTER, READ, WRITE } from '../../constants/permissions'
import './index.css'

const Menu = () => {
  const user = useContext(UserContext)
  const onLogOut = useContext(UserLogoutContext)
  const canRead = user && user.permissions.includes(READ)
  const canWrite = user && user.permissions.includes(WRITE)
  const isAdmin = user && user.permissions.includes(ADMINISTER)

  return (
    <div className="menu">
      <div className="menu__section">
        <div className="menu__item">
          <Link to="/zone"><img src="/logo-white.svg" alt="logo" style={{ width: 35 }} /></Link>
        </div>
        {user && (
          <div className="menu__item">
            <NavLink
              to="/zone"
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              Район
            </NavLink>
          </div>
        )}
        {user && (
          <div className="menu__item">
            <NavLink
              to="/points"
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              Точки
            </NavLink>
          </div>
        )}
        {user && (
          <div className="menu__item">
            <NavLink
              to="/distance"
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              Відстань
            </NavLink>
          </div>
        )}
        {user && (
          <div className="menu__item">
            <NavLink
              to="/converter"
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              Координати XY
            </NavLink>
          </div>
        )}
        {canWrite && (
          <div className="menu__item">
            <NavLink
              to="/beacons"
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              Точки прив&apos;язки
            </NavLink>
          </div>
        )}
        {canRead && (
          <div className="menu__item">
            <NavLink
              to="/info"
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              Довідник
            </NavLink>
          </div>
        )}
        {user && (
          <div className="menu__item">
            <NavLink
              to="/ships"
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              Кораблі
            </NavLink>
          </div>
        )}
        {isAdmin && (
          <div className="menu__item">
            <NavLink
              to="/admin/users"
              className={({ isActive }) => (isActive ? 'menu__active-link' : undefined)}
            >
              Користувачі
            </NavLink>
          </div>
        )}
      </div>
      <div className="menu__section">
        {user && (
          <>
            <div className="menu__item">
              <div className="menu__user" title={user.name}>
                <FontAwesomeIcon icon={faUser} />
              </div>
            </div>
            <div className="menu__item">
              <div className="menu__sign-out" title="Вихід" onClick={onLogOut}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Menu
