import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import ColorPicker from '@uiw/react-color-sketch'
import DmsToDd from '../../utils/dmsToDd'
import Map from './Map'
import checkLatLng from '../../utils/checkLatLng'
import MapForm from '../Common/MapForm'
import Input from '../Common/Input'
import './index.css'

const Points = () => {
  const colorPickerRef = useRef(null)
  const [pointsToDraw, setPointsToDraw] = useState([])
  const [input, setInput] = useState('')
  const [circleRadius, setCircleRadius] = useState(0)
  const [circleBgColor, setCircleBgColor] = useState('#FF8888')
  const [showColorPicker, setShowColorPicker] = useState(false)

  useEffect(() => {
    function handleClickOutside(event) {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setShowColorPicker(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [colorPickerRef])

  const onChange = (e) => {
    setInput(e.currentTarget.value)
  }

  const onChangeCircleRadius = (e) => {
    setCircleRadius(e.currentTarget.value)
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    let error
    const points = input
      .split(/[\n;–-]/)
      .filter((val) => val.trim().length > 0)
      .map((value) => {
        const [lat, lng] = (/[°˚NSEW]/.test(value) ? DmsToDd(value) : value).trim().split(/[^-\d.]+/)
        if (!checkLatLng(lat, lng)) {
          error = `Координати мають бути у форматі: 434522N 0354410E 
        або 44°07'23.3"N 32°17'23.5"E 
        або 45.283632, 31.650995. Роздільник між координатами - дефіс, крапка з комою або перенос рядка (ENTER)`
        }
        return { lat: parseFloat(lat), lng: parseFloat(lng) }
      })

    if (error) {
      toast.warning(error)
    } else {
      // const line = coordinates.length > 2 ? [...coordinates, coordinates[0]] : coordinates
      setPointsToDraw(points)
    }
  }

  const onFormClear = () => {
    setInput('')
    setPointsToDraw([])
  }

  return (
    <div className="points">
      {showColorPicker && (
        <div className="points__color-picker" ref={colorPickerRef}>
          <ColorPicker
            color={circleBgColor}
            onChange={(color) => setCircleBgColor(color.hex)}
          />
        </div>
      )}
      <div className="points__form">
        <MapForm
          title="Показати точки на карті"
          buttonSubmitTitle="Показати на карті"
          onChange={onChange}
          onSubmit={onFormSubmit}
          onClear={onFormClear}
          data={input}
          isDisabled={input.length === 0}
          textRows={5}
          showChangeShowPoints
        />
      </div>
      <div className="points__map">
        <Map
          points={pointsToDraw}
          circleBgColor={circleBgColor}
          circleRadius={circleRadius}
          defaultCenter={pointsToDraw[0]}
          containerHeight="calc(100vh - 320px)"
        />
        <div className="points__show-color" onClick={() => setShowColorPicker(true)}>
          Колір лінії
          <div className="points__show-color-button" style={{ backgroundColor: circleBgColor }} />
        </div>
        <div className="points__circle-radius">
          Радіус
          <Input value={circleRadius} onChange={onChangeCircleRadius} className="points__circle-radius-input" />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Points)
