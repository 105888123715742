import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

const Input = ({ value, type, placeholder, onChange, className }) => (
  <input
    placeholder={placeholder}
    className={`common-input ${className}`}
    type={type}
    onChange={onChange}
    value={value}
  />
)

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

Input.defaultProps = {
  value: '',
  type: 'text',
  placeholder: undefined,
  onChange: () => {},
  className: '',
}
export default Input
