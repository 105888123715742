import React from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, MarkerF, useJsApiLoader, PolylineF } from '@react-google-maps/api'
import TypeLatLng from '../../../types/latLng'

const Map = ({
  markers,
  onClick,
  onDeleteMarker,
  defaultCenter,
  targetMarker,
  linesToDraw,
  lineColor,
  lineThickness,
  containerWidth,
  containerHeight,
  isActive,
  cursor,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })
  return (
    isLoaded
      ? (
        <GoogleMap
          mapContainerStyle={{ width: containerWidth, height: containerHeight }}
          center={defaultCenter}
          zoom={7}
          onClick={onClick}
          options={{ draggableCursor: cursor }}
        >
          {markers.map(({ id, lat, lng, title }) => (
            <MarkerF
              position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
              title={title}
              cursor={isActive ? 'not-allowed' : 'default'}
              onClick={() => onDeleteMarker(id)}
              key={id}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: '#00F',
                fillOpacity: 0.6,
                strokeColor: '#00A',
                strokeOpacity: 0.9,
                strokeWeight: 1,
                scale: 7,
              }}
            />
          ))}
          {targetMarker && (
            <MarkerF
              position={targetMarker}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: '#F00',
                fillOpacity: 0.6,
                strokeColor: '#F55',
                strokeOpacity: 0.9,
                strokeWeight: 1,
                scale: 7,
              }}
            />
          )}
          {linesToDraw.map((lineToDraw, i) => (
            <PolylineF
              key={i} // eslint-disable-line react/no-array-index-key
              path={lineToDraw}
              options={{
                strokeColor: lineColor,
                strokeOpacity: 1.0,
                strokeWeight: lineThickness,
              }}
            />
          ))}
        </GoogleMap>
      )
      : <div>loading...</div>
  )
}

Map.propTypes = {
  markers: PropTypes.arrayOf(TypeLatLng),
  targetMarker: TypeLatLng,
  linesToDraw: PropTypes.arrayOf(PropTypes.arrayOf(TypeLatLng)),
  onClick: PropTypes.func,
  onDeleteMarker: PropTypes.func,
  defaultCenter: TypeLatLng,
  lineColor: PropTypes.string,
  lineThickness: PropTypes.string,
  containerWidth: PropTypes.string,
  containerHeight: PropTypes.string,
  isActive: PropTypes.bool,
  cursor: PropTypes.string,
}

Map.defaultProps = {
  markers: [],
  targetMarker: undefined,
  linesToDraw: [],
  onClick: () => {},
  onDeleteMarker: () => {},
  defaultCenter: {
    lat: 44.562445308294976,
    lng: 32.80599205583778,
  },
  lineColor: '#FF0000',
  lineThickness: 1,
  containerWidth: '100%',
  containerHeight: 'calc(100vh - 230px)',
  isActive: false,
  cursor: 'move',
}

export default Map
