import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

const Textarea = ({ children, placeholder, onChange, className, rows, onKeyDown }) => (
  <textarea
    placeholder={placeholder}
    className={`common-textarea ${className}`}
    onChange={onChange}
    rows={rows}
    value={children}
    onKeyDown={onKeyDown}
  />
)

Textarea.propTypes = {
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  rows: PropTypes.number,
}

Textarea.defaultProps = {
  placeholder: undefined,
  onChange: () => {},
  onKeyDown: () => {},
  className: '',
  rows: 10,
}
export default Textarea
