import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '../../Common/Button'
import { GREEN } from '../../../constants/buttons'
import Input from '../../Common/Input'
import './index.css'
import TypeLatLng from '../../../types/latLng'

const BeaconPopup = ({ beacon, onAdd, onClose }) => {
  const [title, setTitle] = useState('')

  const onChangeTitle = (e) => {
    setTitle(e.currentTarget.value)
  }

  const onClickAdd = (close) => {
    onAdd(title)
    setTitle('')
    close()
  }

  return (
    <Popup
      defaultOpen
      closeOnDocumentClick={false}
      modal
      overlayStyle={{ background: 'rgba(0, 0, 0, 0.5)' }}
      onClose={onClose}
    >
      {(close) => (
        <div className="beacon-popup">
          <div className="beacon-popup__close" onClick={close}>
            <FontAwesomeIcon icon={faTimes} color="#555555" />
          </div>
          <div>{`${beacon.lat}, ${beacon.lng}`}</div>
          <Input
            className="beacon-popup__input"
            placeholder="Назва локації"
            onChange={onChangeTitle}
            value={title}
          />
          <Button
            type={GREEN}
            onClick={() => onClickAdd(close)}
            isDisabled={title.length === 0}
          >
            Додати
          </Button>
        </div>
      )}
    </Popup>
  )
}

BeaconPopup.propTypes = {
  beacon: TypeLatLng.isRequired,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
}

BeaconPopup.defaultProps = {
  onAdd: () => {},
  onClose: () => {},
}

export default BeaconPopup
