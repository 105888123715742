import React from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, MarkerF, useJsApiLoader, CircleF } from '@react-google-maps/api'
import TypeLatLng from '../../types/latLng'

const Map = ({
  points,
  defaultCenter,
  circleRadius,
  circleBgColor,
  containerWidth,
  containerHeight,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })
  return (
    isLoaded
      ? (
        <GoogleMap
          mapContainerStyle={{ width: containerWidth, height: containerHeight }}
          center={defaultCenter}
          zoom={7}
          options={{ draggableCursor: 'move' }}
        >
          {points.map(({ lat, lng }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <CircleF
                center={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                options={{
                  fillColor: circleBgColor,
                  fillOpacity: 0.6,
                  strokeColor: circleBgColor,
                  strokeOpacity: 0.9,
                  strokeWeight: 2,
                  radius: (parseInt(circleRadius, 10) || 0) * 1000,
                }}
              />
              <MarkerF
                position={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  fillColor: '#000000',
                  fillOpacity: 0.6,
                  strokeColor: '#000000',
                  strokeOpacity: 0.9,
                  strokeWeight: 1,
                  scale: 3,
                }}
              />
            </div>
          ))}
        </GoogleMap>
      )
      : <div>loading...</div>
  )
}

Map.propTypes = {
  points: PropTypes.arrayOf(TypeLatLng),
  defaultCenter: TypeLatLng,
  circleRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circleBgColor: PropTypes.string,
  containerWidth: PropTypes.string,
  containerHeight: PropTypes.string,
}

Map.defaultProps = {
  points: [],
  circleRadius: 10,
  circleBgColor: '#FF8888',
  defaultCenter: {
    lat: 44.562445308294976,
    lng: 32.80599205583778,
  },
  containerWidth: '100%',
  containerHeight: 'calc(100vh - 230px)',
}

export default Map
